import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useOrganization } from '../components/OrganizationProvider';
import { CompleteUploadRequest } from '../components/shared/useFileUpload';
// import { sampleClosingSummaries } from './mockData';
import { CreateInviteRequest,DownloadLinkType, CompleteMfaEnrollmentRequest, GetConfigResponse, GetDownloadResponse, GetOrderResponse, GetOrdersResponse, PortalNotification, CompleteUploadResponse, NeedsSigningResponse, GetUnauthedSigningResponse, GetUnauthedDownloadResponse, UpdateSmsPreferenceStatusRequest, PortalNotificationReference } from '../types';
import { GetUploadResponse } from '../types/portalTypes/api/GetUpload';
import { GetUploadsResponse } from '../types/portalTypes/api/GetUploads';
import { track } from './analytics';
import { getAudience, getScopes } from './auth0Utils';
import { GetIdCheckUrlResponse } from '../types/portalTypes/api/GetIdCheckUrl';

export type OrderKey = { orderKey: string }

// TODO: pull in a better fetch abstraction layer and merge in the important
// auth0 bits below.  This one assumes you want to kick off the fetch immediately which
// is not desirable for things like getting a download link after clicking on it.
// it should look more like:
//
//    const api = useApi()
//    const { data, error } = api.getDownloadLink()
//

// FE and BE origins match CORS not needed, except when running in localhost.
const origin = process.env.REACT_APP_API_ORIGIN ?? ''

export function useCreateUploadUrl({ CompanyIdOrderIdHash, FileExtension, search }: {
  CompanyIdOrderIdHash: string;
  // FileName: string;
  FileExtension: string;
  search: string;
}) {
  return useApi<GetUploadResponse>(`order/${CompanyIdOrderIdHash }/upload/createUrl${search}`, {
    method: 'POST',
    body: JSON.stringify({ FileExtension }),
  });
}


export function useCompleteUpload({ CompanyIdOrderIdHash, Files, search, notificationId }: CompleteUploadRequest) {
  return useApi<CompleteUploadResponse>(`order/${CompanyIdOrderIdHash }/upload/complete${search}`, {
    method: 'POST',
    body: JSON.stringify({ Files, notificationId })
  }, -1);
}


export function useGetUploads({ CompanyIdOrderIdHash, search }: {
  CompanyIdOrderIdHash: string;
  search: string;
}) {
  return useApi<GetUploadsResponse>(`order/${CompanyIdOrderIdHash}/uploads${search}`);
}

export function useGetDownloadLink({ CompanyIdOrderIdHash, FileName, Type, search, DocumentBatchId, DocumentFileName }: {
  CompanyIdOrderIdHash: string;
  FileName: string;
  Type: DownloadLinkType;
  search: string;
  DocumentBatchId?: string;
  DocumentFileName?: string;
}) {
  return useApi<GetDownloadResponse>(`order/${CompanyIdOrderIdHash }/download${search}`, {
    method: 'POST',
    body: JSON.stringify({ FileName, Type, DocumentFileName, DocumentBatchId }),
  }, -1);
}

export function useGetOrders() {
  // 	// DEV Helper to return local mock data
  // 	if (process.env.REACT_APP_MOCK_SERVER) {
  // 		return Promise.resolve(sampleGetOrderResponse())
  // 	}
  return useApi<GetOrdersResponse>(`orders`);
}

export function useGetOrder(orderKey: string, search: string) {
  return useApi<GetOrderResponse>(`order/${orderKey}${search}`);
}

export function useStartMfaEnrollment({ orderKey, search, ...body }: OrderKey & { search: string, oobChannel: string }) {
  return useApi<any>(`order/${orderKey}/startMfaEnrollment${search}`, {
    method: 'POST',
    body: JSON.stringify(body)
  }, -1);
}


export function useCompleteMfaEnrollment({ orderKey, search, ...body }: CompleteMfaEnrollmentRequest & OrderKey & { search: string }) {
  return useApi<any>(`order/${orderKey}/completeMfaEnrollment${search}`, {
    method: 'POST',
    body: JSON.stringify(body)
  }, -1);
}

export function useGetNotifications(orderKey: string, search: string) {
  return useApi<PortalNotificationReference[]>(`order/${orderKey}/notifications${search}`, {}, -1);
}

export function useGetNotification(orderKey: string, notificationId: string, search: string) {
  return useApi<PortalNotification>(`order/${orderKey}/notification/${notificationId}${search}`);
}

export function useUserCancelTask(orderKey: string, notificationId: string, search: string) {
  return useApi(`order/${orderKey}/notification/${notificationId}/userCancel${search}`, {
    method: 'POST',
  }, -1);
}

export function useUserResetTask(orderKey: string, notificationId: string, search: string) {
  return useApi(`order/${orderKey}/notification/${notificationId}/userReset${search}`, {
    method: 'POST',
  }, -1);
}

export function useUserCompleteTask(orderKey: string, notificationId: string, search: string) {
  return useApi(`order/${orderKey}/notification/${notificationId}/userComplete${search}`, {
    method: 'POST',
  }, -1);
}

export function useGetNeedsSigning(orderKey: string, notificationKey: string, search: string) {
  return useApi<NeedsSigningResponse>(`order/${orderKey}/notification/${notificationKey}/needsSigning${search}`);
}

export function useGetNotificationStatuses(orderKey: string, notificationKey: string, search: string) {
  return useApi<Partial<PortalNotification>>(`order/${orderKey}/notification/${notificationKey}/eventStatuses${search}`, {}, -1);
}

export function useFormSubmit(orderKey: string, formKey: string, formData: any, notificationId: string, search: string) {
  return useApi<PortalNotification>(`order/${orderKey}/form/${formKey}/submit${search}`, {
    method: 'POST',
    body: JSON.stringify({ formData, notificationId })
  }, -1);
}

export function useUpdateSmsPreferenceStatus({ orderKey, search, consentState }: {
  orderKey: string;
  search: string;
  consentState: UpdateSmsPreferenceStatusRequest;
}) {
  return useApi<any>(`communicationPreference${search}`, {
    method: 'POST',
    body: JSON.stringify({
      ...consentState,
      orderKey,
    })
  }, -1);
}

export function useGetIdCheckUrl(orderKey: string, notificationId: string, search: string) {
  return useApi<GetIdCheckUrlResponse>(`order/${orderKey}/notification/${notificationId}/idCheck${search}`, {}, -1);
}

export function useGetUnauthedSigning(pageUrl: string, hash: string) {
  const url = `${origin}/api/unauthed/signing/${hash}?pageUrl=${encodeURIComponent(pageUrl)}`

  const [state, setState] = useState({ loading: false, error: '', data: null as null | GetUnauthedSigningResponse })
  const [pin, setPin] = useState('')

  function resetRequestState() {
    setState({ loading: false, error: '', data: null })
  }

  useEffect(() => {
    if (pin) {
      ;(async () => {
        setState({ loading: true, error: '', data: null })

        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify({ Pin: pin }),
          headers: {
            'Content-Type': 'application/json',
          }
        })

        if (response.ok) {
          const data = await response.json()
          setState({ loading: false, error: '', data })
          return

        } else {
          let json: any
          try {
            json = await response.json()
          } catch (error) {
            track('error', 'useGetUnauthedSigning', {}, {
              message: 'ERROR: response was not JSON',
              error: JSON.stringify(error)
            })
          }

          let error = json?.userErrorMessage || 'Error'

          setState({ loading: false, error, data: null })
        }
      })();

      // Since we're depending on pin change to run useEffect hook, reset the pin
      // to allow resubmission of same pin in event of an error
      setPin('')
    }
  }, [url, pin])

  return { resetRequestState, requestSigningUrl: setPin, ...state }
}

export function useGetUnauthedDownload(pageUrl: string, hash: string) {
  const url = `${origin}/api/unauthed/signing/${hash}/download?pageUrl=${encodeURIComponent(pageUrl)}`

  const [state, setState] = useState({ loading: false, error: '', data: null as null | GetUnauthedDownloadResponse })
  const [pin, setPin] = useState('')

  function resetRequestState() {
    setState({ loading: false, error: '', data: null })
  }

  useEffect(() => {
    if (pin) {
      ;(async () => {
        setState({ loading: true, error: '', data: null })

        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify({ Pin: pin }),
          headers: {
            'Content-Type': 'application/json',
          }
        })

        if (response.ok) {
          const data = await response.json()
          setState({ loading: false, error: '', data })
          return

        } else {
          let json: any
          try {
            json = await response.json()
          } catch (error) {
            track('error', 'useGetUnauthedSigning', {}, {
              message: 'ERROR: response was not JSON',
              error: JSON.stringify(error)
            })
          }

          let error = json?.userErrorMessage || 'Error'

          setState({ loading: false, error, data: null })
        }
      })();

      // Since we're depending on pin change to run useEffect hook, reset the pin
      // to allow resubmission of same pin in event of an error
      setPin('')
    }
  }, [url, pin])

  return { resetRequestState, requestDownloadUrl: setPin, ...state }
}


export function useCreateInvite({ CompanyDomain }: {
  CompanyDomain: string;
}) {
  const [state, setState] = useState({ loading: false, error: '', data: null as null | CreateInviteRequest })
  const [email, setEmail] = useState('')
  const url = `${origin}/api/unauthed/CreateInvite`

  function resetRequestState() {
    setState({ loading: false, error: '', data: null })
  }

  useEffect(() => {
    if (email) {
      track('create_invite initiated', CompanyDomain);

      ;(async () => {
        setState({ loading: true, error: '', data: null })

        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify({ UserEmail: email, CompanyDomain }),
          headers: {
            'Content-Type': 'application/json',
          }
        })

        if (response.ok) {
          // NOTE: doesn't mean it was a valid email or that invitation sent
          track('create_invite success', CompanyDomain);

          const data = await response.json()
          setState({ loading: false, error: '', data })
          return

        } else {
          let json: any
          try {
            json = await response.json()
          } catch (error) {
            track('error', 'useCreateInvite', {}, {
              message: 'ERROR: response was not JSON',
              error: JSON.stringify(error)
            })
          }

          let error = json?.userErrorMessage || 'Error'

          setState({ loading: false, error, data: null })
        }
      })();

      // Reset email to allow resubmission of same email in event of an error
      setEmail('')
    }
  }, [url, email, CompanyDomain])

  return { resetRequestState, requestInvite: setEmail, ...state }
}

export function useGetConfig(pageUrl: string) {
  const [state, setState] = useState({ loading: true, error: '', data: null as null | GetConfigResponse })
  const url = `${origin}/api/unauthed/GetConfig?pageUrl=${encodeURIComponent(pageUrl)}`

  useEffect(() => {
    ;(async () => {
      const response = await fetch(url)

      // Invalid invite error
      if (response.status === 422) {
        track('error', '422 Mapping already exists (used invite link)');

        setState({
          loading: false,
          data: null,
          error: 'This invitation is not valid. It may have already been used or is expired. Please request a new invitation.',
        })

        return
      }

      if (response.ok) {
        const data = await response.json()
        setState({ loading: false, error: '', data })
        return

      } else {
        let json: any
        try {
          json = await response.json()
        } catch (error) {
          track('error', 'useGetConfig', {}, {
            message: 'ERROR: response was not JSON',
            error: JSON.stringify(error)
          })
        }

        let error = json?.userErrorMessage || 'We had trouble finding your invitation.  Please try requesting another invite.'

        setState({ loading: false, error, data: null })
      }
    })();
  }, [url])

  return state
}


// https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#4-create-a-useapi-hook-for-accessing-protected-apis-with-an-access-token
// TMP Hack - pass in -1 for initialRefreshIndex to not fire immediately
// TODO: replace with https://github.com/ilyalesik/react-fetch-hook
export const useApi = <TResponse>(path: string, options = {}, initialRefreshIndex=0) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const organization = useOrganization()
  let scope = getScopes(organization?.MfaRequired)
  const audience = getAudience(organization?.MfaRequired)

  const initialState = {
    error: null,
    loading: true,
    data: null as null | TResponse,
  }

  const [state, setState] = useState(initialState);

  const [refreshIndex, setRefreshIndex] = useState(initialRefreshIndex);

  function resetState(state = {}) {
    setState({ ...initialState, ...state })
  }

  useEffect(() => {
    if (refreshIndex === -1) {
      setState({
        ...state,
        loading: false,
      });
      return
    }

    (async () => {
      try {
        // Set loading (back) to true for refreshing
        setState({
          ...state,
          loading: true,
        });

        //@ts-ignore
        const { ...fetchOptions } = options;

        const url = `${origin}/api/${path}`
        console.log('[api] url', url)

        // Try getting the token silently in the background, if it fails
        // redirect to login.
        console.log('[api] getAccessTokenSilently()')
        let accessToken = ''
        try {
          accessToken = await getAccessTokenSilently({ audience, scope });
        } catch(e) {
          console.warn('getAccessTokenSilently() FAILED, redirecting to login…', e)
          await loginWithRedirect({
            appState: { returnTo: window.location.pathname + window.location.search }
          })

          setState({
            ...state,
            data: null,
            error: null,
            loading: true,
          });

          return;
        }

        const res = await fetch(url, {
          ...fetchOptions,
          headers: {
            //@ts-ignore
            ...fetchOptions.headers,
            // Add the Authorization header to the existing headers
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
        console.log('api result ok?', res.ok, res)

        if (res.ok) {
          let data: any = {}
          try {
            data = await res.json()
          } catch(e) {
            // handle empty response
            track('error', 'useApi', {}, {
              message: 'caught an empty success response, return {} for the body',
              error: JSON.stringify(e),
            })
            data = {}
          }

          setState({
            ...state,
            data,
            error: null,
            loading: false,
          });

        } else {
          let error:any = null
          try {
            error = await res.json()
          } catch (e) {
            track('error', 'useApi', {}, {
              message: 'caught error trying to parse non-ok response as json.  Returning generic error message',
              error: JSON.stringify(e),
            })

            error = { userErrorMessage: 'An error occurred' }
          }
          setState({
            ...state,
            //@ts-ignore
            error,
            loading: false,
          });
        }

      } catch (error) {
        setState({
          ...state,
          //@ts-ignore
          error,
          loading: false,
        });
      }
    })();
  // TODO - fix this later
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshIndex]);


  return {
    ...state,
    refresh: () => setRefreshIndex((prevIndex) => prevIndex + 1),
    resetState,
  };
};
